.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #FFF7F8;
  min-height: 100vh;
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: #282c34;
}

.lighter {
  color: #787c94;
}

.icon {
  height: calc(8px + 1.5vmin);
}

.bigicon {
  height: calc(16px + 1.5vmin);
  margin-left: 10px;
  margin-right: 10px;
}

.links-container {
  display: flex;
  margin-top: 10px; /* Adjust as needed */
}

a{
  color: #41baeb;
  text-decoration: none;
}

